import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      rows: [],
      headerChecked: false,
      headerIndeterminate: false
    }
  },
  methods: {
    isInRows (id: never): boolean {
      return this.rows.includes(id)
    },
    rowClickEvent (checked: boolean, id: never) {
      if (checked) {
        if (!this.isInRows(id)) {
          this.rows.push(id)
        }
      } else {
        this.rows = this.rows.filter((item: never) => {
          return item !== id
        })
      }
    },
    setRows (rows: never[]) {
      this.rows = rows
    },
    resetRows () {
      this.rows = []
    }
  }
})
