<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Produits</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par nom" @change="changePage(1)" v-model="textFilter">
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <product-category-selector placeholder="Catégorie" class="product-category-select" inputId="product-category" @input="selectCategory" :value="categoryFilter" />
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="checkbox" @click="selectCategory('none')" :checked="categoryFilter === 'none'">
                      <label class="form-check-label ml-2">Produits sans catégorie</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline">
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1">
                      <a class="btn btn-sm btn-link" @click.prevent="syncData" >
                        <fa-icon class="fa-2x text-info" :icon="['fas', 'sync']"></fa-icon>
                      </a>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset()" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-collapse v-model="isActionBarVisible">
          <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      Sélection: {{ rows.length }} élement(s)
                    </div>
                    <div class="ml-4 hidden-md"></div>
                    <div class="form-group mt-1  mobile-wid-100">
                      <a class="btn btn-sm btn-outline-primary" @click="handleChangeCategory" >
                        Changer la catégorie
                      </a>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </b-collapse>

        <div class="card mb-4 support-requests">
          <div class="card-body p-0">
            <div v-if="products.length === 0 && !isFetching" class="card-body text-center">
              <div class="py-3">Vous n'avez aucun produit.</div>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="products"
              :show-actions="false"
              no-top-border
              hover
              :link="(item) => `/products/${item.id}`"
              @sortBy="handleSortBy"
            >
              <template v-slot:selectRow>
                <checkbox-cell :checked="headerChecked" id="header" :indeterminate="headerIndeterminate" :clickEvent="getAllRow" />
              </template>
            </collection-table>

            <div v-if="paginatedData.pageCount > 1 && !isFetching" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination :current-page="page" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'
import supportRequestFunctions from '@/mixins/supportRequestFunctions'
import handleForm from '@/mixins/handle-form'
import { getStorageFileSource } from '@/utils/files'
import Image from '@/components/common/Image'
import formats from '@/mixins/formats'
import selectableRow from '@/mixins/selectableRow'
import Pagination from '@/components/common/Pagination'
import CheckboxCell from '@/components/common/CheckboxCell'
import ItemCell from '@/components/categories/ItemCell'
import ProductCategorySelector from '@/components/forms/inputs/ProductCategorySelector'

export default {
  components: {
    Pagination,
    CheckboxCell,
    BCollapse,
    ProductCategorySelector
  },
  directives: {
    'b-toggle': VBToggle
  },
  mixins: [formatsDate, userData, formatsCurrency, supportRequestFunctions, formats, selectableRow, handleForm],
  data () {
    return {
      isActionBarVisible: false
    }
  },
  watch: {
    rows: {
      handler (rows) {
        this.isActionBarVisible = rows.length > 0

        if (rows.length > 0 && rows.length !== this.perPage) {
          this.headerIndeterminate = true
          this.headerChecked = false
        } else if (rows.length > 0 && rows.length === this.perPage) {
          this.headerIndeterminate = false
          this.headerChecked = true
        } else {
          this.headerIndeterminate = false
          this.headerChecked = false
        }
      },
      immediate: true
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.products.isLoading
    },
    isFetching () {
      return this.$store.state.products.isFetching
    },
    productCategories () {
      return this.$store.state.products.categories
    },
    cols () {
      return [
        {
          header: '',
          headerCheckbox: true,
          property: 'id',
          component: CheckboxCell,
          props: (item) => {
            return {
              id: item.id,
              checked: this.isInRows(item.id),
              clickEvent: this.rowClickEvent
            }
          }
        },
        {
          header: 'Image Globale',
          property: 'productPhotos',
          component: Image,
          props: (item) => {
            const photo = item.productPhotos?.find(photo => photo.isGlobal)
            return {
              src: photo?.file ? getStorageFileSource(photo.file) : undefined,
              alt: item.name
            }
          }
        },
        {
          header: 'Nom du produit',
          property: 'name'
        },
        {
          header: 'Categorie',
          property: 'categoryId',
          component: ItemCell,
          props: (item) => {
            return {
              categoryId: item.categoryId,
              categories: this.productCategories
            }
          }
        },
        {
          header: 'Boutique',
          property: 'store.name'
        },
        {
          header: 'Prix par défaut',
          property: 'defaultPrice',
          method: (product) => {
            return !product.defaultPrice
              ? this.$money(product.defaultRegularPrice)
              : this.$money(product.defaultPrice)
          }
        },
        {
          header: 'Type',
          property: 'type',
          method: (product) => {
            return product.type === 'simple' ? 'Simple' : 'Variantes'
          }
        },
        {
          header: 'Variantes',
          property: 'variants',
          headerClass: 'variants-col-width',
          method: (product) => {
            if (product.type === 'simple') {
              return '-'
            }

            return product.variants.length
          }
        }
      ]
    },
    paginatedData () {
      return this.$store.state.products.paginatedData
    },
    products () {
      return this.paginatedData.data
    },
    textFilter: {
      get () {
        return this.$store.state.products.textFilter
      },
      set (value) {
        this.$store.commit('products/SET_TEXT_FILTER', value)
      }
    },
    categoryFilter: {
      get () {
        return this.$store.state.products.categoryFilter
      },
      set (value) {
        this.$store.commit('products/SET_CATEGORY_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.products.perPage
      },
      set (value) {
        this.$store.commit('products/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.products.page
    }
  },
  methods: {
    async updateProductsCategories (categoryId) {
      await this.$store.dispatch('products/patchProducts', { products: this.rows, categoryId })
      this.rows = []

      this.$toasted.success('Produit(s) mis à jour avec succès.')
    },
    async handleChangeCategory () {
      try {
        await this.$modal.openFormModal({
          title: `Édition de la catégorie pour ${this.rows.length} produit(s)`,
          fields: [
            {
              name: 'categoryId',
              label: 'Catégorie',
              type: 'product-category-selector',
              required: true
            }
          ],
          initialValues: { categoryId: '' },
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.categoryId) {
              setErrors({
                categoryId: ['Ce champ est requis.']
              })
              return
            }

            await this.updateProductsCategories(values.categoryId)

            confirm()
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    selectCategory (option) {
      if (this.categoryFilter === 'none' && option === 'none') {
        this.categoryFilter = ''
      } else {
        this.categoryFilter = option
      }
      this.changePage(1)
    },
    getAllRow (checked) {
      if (this.headerIndeterminate) {
        this.headerChecked = false
        this.headerIndeterminate = false
        this.resetRows()
      } else {
        this.headerChecked = checked

        if (checked) {
          const rows = this.products.map((product) => {
            return product.id
          })
          this.setRows(rows)
        } else {
          this.resetRows()
        }
      }
    },
    async changePage (page) {
      this.isActiveSelectAll = false
      this.resetRows()
      await this.fetchProducts(page)
    },
    async fetchProducts (page) {
      await this.$store.dispatch('products/fetch', { page })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchProducts()
    },
    async syncData () {
      await this.fetchProducts(this.page)
      this.$toasted.info('Les produits sont actualisées')
    },
    reset () {
      this.textFilter = ''
      this.categoryFilter = ''
      this.isActiveSelectAll = false
      this.resetRows()
      this.fetchProducts()
    }
  },
  created () {
    this.fetchProducts()
    this.$store.dispatch('products/fetchCategories')
  }
}
</script>

<style lang="scss">
.support-requests {
  .col-date {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }
}
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
.product-category-select {
  min-width: 300px;

  .type-input {
    min-width: 300px;
  }
}
</style>
