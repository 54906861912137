

































































































import Vue from 'vue'
import { BTabs, BTab } from 'bootstrap-vue'

export default Vue.extend({
  components: {
    'b-tabs': BTabs,
    'b-tab': BTab
  },
  props: {
    description: {
      type: String,
      default: ''
    },
    specifications: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  }
})
