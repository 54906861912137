<template>
  <div>
    <b-spinner v-if="categories.length === 0"></b-spinner>
    <span v-else>
      {{ categoryName }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: false,
      default: () => []
    },
    categoryId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    categoryName () {
      const category = this.categories.find((x) => x.id === this.categoryId)

      return category ? category.name : 'Pas de catégorie'
    }
  }
}
</script>
