<template>
  <div :class="containerClass" @click.prevent="handleClick">
    <input v-if="!indeterminate" :class="inputClass" :id="id" :name="id" type="checkbox" :checked="checked">
    <input v-else :indeterminate.prop="true" :class="inputClass" :id="id" :name="id" type="checkbox">
    <label :class="labelClass" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    indeterminate: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    clickEvent: {
      type: Function,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: 'custom-control custom-checkbox'
    },
    inputClass: {
      type: String,
      default: 'custom-control-input'
    },
    labelClass: {
      type: String,
      default: 'custom-control-label'
    }
  },
  methods: {
    handleClick (evt) {
      let checked = !this.checked

      if (this.indeterminate) {
        checked = false
      }

      if (this.clickEvent) {
        this.clickEvent(checked, this.id)
      }

      evt.stopPropagation()
    }
  }
}
</script>
